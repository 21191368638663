<template>
    <div v-if="user">
        <TransitionsFadeUp>
            <ElementsForm @submit="sendSupportRequest" v-if="state.open" class="fixed bottom-0 md:bottom-[4.5rem] right-0 md:right-4 bg-white dark:bg-slate-700 dark:shadow-gray-800 dark:shadow-lg rounded-lg overflow-auto shadow w-screen md:w-[32vw] md:min-w-[610px] h-screen md:h-[60vh] md:min-h-[780px] z-50"
                 v-slot="{ validation }">
                <h2 class="w-full bg-secondary dark:bg-primary text-white p-6 text-2xl">Hilfe</h2>
                <div class="p-6">
                    <div class="mb-8">
                        <p>Ihr Ansprechpartner:</p>
                        <template v-if="state.pending">
                            <p class="my-1.5 py-2 bg-blue-100 dark:bg-gray-600 rounded w-3/5 h-5 animate-pulse text text-transparent">&nbsp;</p>
                            <p class="my-1.5 py-2 bg-blue-100 dark:bg-gray-600 rounded w-2/5 h-5 animate-pulse text text-transparent">&nbsp;</p>
                            <p class="my-1.5 py-2 bg-blue-100 dark:bg-gray-600 rounded w-3/6 h-5 animate-pulse text text-transparent">&nbsp;</p>
                        </template>
                        <template v-else>
                            <p>{{state.contact.name}}</p>
                            <p>{{state.contact.email}}</p>
                            <p>{{state.contact.phone}}</p>
                        </template>
                    </div>
                    <ElementsRadioButton v-if="!state.done" v-model="state.type" :options="[
                        { value: 'application', label: 'Fragen zur Datenerfassung' },
                        { value: 'technical', label: 'Technische Probleme' },
                    ]" :validation="validation" path="store.type" required label="Welchen Bereich umfasst Ihre Anfrage?" />
                    <ElementsTextArea v-if="!state.done" v-model="state.message" placeholder="Schreiben Sie ihr Anliegen hier" required :validation="validation" />
                    <div v-else>{{state.message}}</div>
                    <div class="mt-2">Ihre Kontaktdaten:</div>
                    <div class="grid gap-1">
                        <div>
                            <div v-if="state.replyTo?.email && !state.replyTo.editEmail" class="flex gap-0.5 items-center">
                                {{state.replyTo.email}} 
                                <ElementsButton color="transparent" icon="pencil" noShadow class="hover:text-primary" @click="state.replyTo.editEmail = true" />
                            </div>
                            <ElementsInput v-else v-model="state.replyTo.email" type="email" label="E-Mail" />
                        </div>
                        <div>
                            <div v-if="state.replyTo?.phone && !state.replyTo.editPhone" class="flex gap-0.5 items-center">
                                {{state.replyTo.phone}} 
                                <ElementsButton color="transparent" icon="pencil" noShadow class="hover:text-primary" @click="state.replyTo.editPhone = true" />
                            </div>
                            <ElementsInput v-else v-model="state.replyTo.phone" type="phone" label="Telefon" />
                        </div>
                    </div>
                    <div class="text-sm text-gray-500 dark:text-gray-300 mt-3">
                        Es wird ein Bildschirmfoto ihrer aktuellen Ansicht der Anfrage angehängt. Mit dem Absenden erklären Sie sich mit den <NuxtLink to="/privacy" target="_blank">Datenschutzbestimmungen</NuxtLink> einverstanden.
                    </div>
                    <ElementsButton v-if="!state.done" type="submit" icon="envelope" class="my-5" color="empty" :loading="state.loading">Absenden</ElementsButton>
                    <div v-else>
                        <ElementsAlert :active="true" type="success" class="text-white my-10">
                            Ihre Anfrage wurde erfolgreich übermittelt.
                        </ElementsAlert>
                    </div>
                    <ElementsAlert :active="state.error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</ElementsAlert>
                </div>
            </ElementsForm>
        </TransitionsFadeUp>
        <button @click="openSupportDialog" class="fixed group right-4 md:right-8 bottom-4 md:bottom-8 rounded-full bg-primary z-50 w-16 md:w-24 h-16 md:h-24 flex items-center justify-center text-white text-3xl transition-all duration-300 cursor-pointer hover:bg-secondary">           
            <ThemeIconsLoading v-if="state.loading" size="medium" />
            <ElementsIcon v-else-if="state.open" icon="cancel" size="medium" />
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 md:w-8 md:h-8 overflow-visible fill-current">
                <path
                    class="transition-all duration-300 origin-center group-hover:scale-110 group-hover:translate-x-0.5 group-hover:translate-y-0.5 group-focus:group-hover:rotate-[-20deg]"
                    d="m 20,9.08 h -0.012 c 0,0.237 0,0.474 -0.012,0.712 C 19.59,15.2 14.647,19.778 9.084,19.981 v 0.015 C 10.509865,22.468977 13.145411,23.994819 16,24 h 5 c 1.656854,0 3,-1.343146 3,-3 v -5 c -0.0029,-2.855194 -1.527328,-5.492404 -4,-6.92 z"
                    />
                <path
                    class="transition-all duration-300 origin-center group-hover:scale-125 group-focus:group-hover:rotate-[40deg] group-focus:group-hover:-translate-x-1"
                    d="m 8.8888131,18.043367 h -5.7 c -1.4929999,0 -2.99999996,-1.134 -2.99999996,-3.666 V 9.3373661 C 0.23232414,4.5824816 3.8134378,0.60589761 8.5378131,0.06636561 14.011357,-0.33097339 18.563153,4.2208216 18.165813,9.6943661 17.625572,14.420774 13.64582,18.002423 8.8888131,18.043367 Z"
                    />
            </svg>
        </button>
    </div>
</template>
<script setup>
import html2canvas from 'html2canvas'
const props = defineProps({
    open: { type: Boolean, default: false, },
})
const emit = defineEmits(['opened'])
watch(props, () => props.open && !state.open ? setTimeout(() => openSupportDialog(), 1) : null)

const user = useStrapiUser()
const isProducer = useIsProducer()
const { findOne, create } = useStrapi4()


const state = reactive({
    open: false,
    done: false,
    error: false,
    loading: false,
    pending: true,
    message: '',
    type: '',
    image: null,
    currentBusiness: null,
    replyTo: {
        email: '',
        editEmail: false,
        phone: '',
        editPhone: false,
    },
    contact: {
        id: 2,
        name: 'Standortförderung Zürioberland',
        email: 'info@zuerioberland.ch',
        phone: '+41 52 396 50 90',
    },
    technicalContact: {
        id: 2,
        name: 'Standortförderung Zürioberland',
        email: 'info@zuerioberland.ch',
        phone: '+41 52 396 50 90',
    },
})

const sendSupportRequest = async() => {
    state.loading = true
    try {
        let data = {
            to: state.type == 'application' ? state.contact.id : state.technicalContact.id,
            type: state.type,
            message: state.message,
            image: state.image,
            replyTo: state.replyTo,
            url: window.location.href,
        }
        if(isProducer){ //Producer
            let result = await create('businesses/' + state.currentBusiness + '/support', data)
            if(result){
                state.error = false
                state.done = true
                state.replyTo.editEmail = false
                state.replyTo.editPhone = false

            }
            else
                state.error = true
        }
        else { //RM/DO
            let result = await create('organisations/' + user?.value?.organisation?.id + '/support', data)
            if(result){
                state.error = false
                state.done = true
                state.replyTo.editEmail = false
                state.replyTo.editPhone = false

            }
            else
                state.error = true

        }
    } catch(e) {
        state.error = true
        console.log(e)
    }
    state.loading = false
}

const openSupportDialog = async() => {
    if(state.open) return state.open = false
    state.loading = true

    try {
        let canvas = await html2canvas(document.body, {
            windowWidth: document.clientWidth,
            windowHeight: document.clientHeight,
            scale: 2
        })
        state.image = canvas.toDataURL()
        state.open = true
        
        if(isProducer && state.pending) {
            //Load organisation contact by user 
            let { data: business } = await useCurrentBusiness()
            let mainRegionId = business?.value?.data?.attributes?.organisations?.data?.find( o => o?.attributes?.isMainRegion)
            console.log(business?.value?.data?.attributes?.organisations?.data);
            mainRegionId = mainRegionId ? mainRegionId?.id : business?.value?.data?.attributes?.organisations?.data?.[0]?.id
            let organisation = await findOne('organisations', mainRegionId, { populate: ['address']})

            state.currentBusiness = business?.value?.data?.id
            state.replyTo.email = user?.value?.email
            state.replyTo.phone = business?.value?.data?.attributes?.contactAddress?.phone

            state.contact.id = organisation?.data?.id
            state.contact.name = organisation?.data?.attributes?.address?.company ?? organisation?.data?.attributes?.name
            state.contact.email = organisation?.data?.attributes?.address?.email
            state.contact.phone = organisation?.data?.attributes?.address?.phone   
        }
        else {
            state.replyTo.email = user?.value?.email
            if(user?.value?.organisation?.id) {
                let organisation = await findOne('organisations', user?.value?.organisation?.id, { populate: ['address']})
                state.replyTo.phone = organisation?.data?.attributes?.address?.phone
            }
        }
        if(!state.replyTo.email) state.replyTo.editEmail = true
        if(!state.replyTo.phone) state.replyTo.editPhone = true
        state.pending = false
        emit('opened')

    } catch(e)  {
        console.log(e)
    }
    state.loading = false
}

</script>