<template>
  <div>
    <div>
        <ThemeHeader @help="helpOpen = true"/>
        <div class="z-0" v-bind:class="{
                'bg-gray-100 dark:bg-gray-800': !route.meta?.whiteBackground,
                'bg-white dark:bg-slate-900': route.meta?.whiteBackground,
                'pb-20' : route.meta.fullwidth === undefined || !route.meta.fullwidth,
            }">
            <div class="min-h-screen font-sans dark:text-white" v-bind:class="{
                'px-3 md:px-4 mx-auto sm:px-6 max-w-7xl' : route.meta.fullwidth === undefined || !route.meta.fullwidth,
                'min-w-screen top-0' : route.meta.fullwidth !== undefined && route.meta.fullwidth,
            }">
            <ClientOnly>
              <slot />
              <template #fallback>
                <div class="flex w-full h-screen justify-center items-center">
                  <ThemeIconsLoading />
                </div>
              </template>
            </ClientOnly>
              <!--<NuxtErrorBoundary @error="handleError">
              </NuxtErrorBoundary>
              -->
            </div>
        </div>
    </div>
    <div class="dark:bg-slate-700 dark:text-white">
        <ThemeFooter v-if="route.path != '/login'" />
        <LogoutWatcher />
        <ElementsPageLoadIndicator color="#6200ee" />
        <SupportBubble :open="helpOpen" @opened="helpOpen = false" />
    </div>
  </div>
</template>
<script setup>
const route = useRoute()
const config = useRuntimeConfig()
const isProduction = config.public.strapi.instance == 'production'
useHead({
    title: 'Echtregional',
    meta: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' }
    ],
    script: isProduction ? [
      { src: '//cdn.mouseflow.com/projects/4857ef72-e349-4354-b658-610be65a1677.js', defer: true, async: true },
      { src: 'https://www.googletagmanager.com/gtag/js?id=G-8LM60M2QYE', defer: true, async: true },
      { src: '/js/analytics.js', defer: true, async: true },
    ] : [
      
    ],
    pageTransition: {
        name: 'page',
        duration: {
            enter: 250,
            leave: 190,
        },
    },
})
usePageTitle()

const helpOpen = ref(false)

const handleError = (e) => {
  console.log('error occured: ', e)
}

</script>
<style>
.page-enter-active {
  transition: all 250ms ease-in;
}
.page-leave-active {
  transition: all 190ms ease-out;
}
.page-enter,
.page-leave-to {
  opacity: 0;
  filter: blur(1px) saturate(0.5);
}
</style>